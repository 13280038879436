import styled from 'styled-components';
import img from '../assets/girl-cleaning-snow.webp';

const Container = styled.div`
  margin: 3rem auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  max-width: 1280px;
  justify-content: center;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 4rem;
    text-align: left;
  }
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Text = styled.p`
  color: black;
  text-align: left;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  padding: 0 1rem;

  @media (min-width: 768px) {
    margin: 0 2rem;
  }
`;

const Image = styled.img`
  border-radius: 15px;
  width: 100%;

  @media (min-width: 768px) {
    width: 25rem;
  }
`;

export default function TextSection() {
  return (
    <Container>
      <ImageWrapper>
        <Image src={img} alt="Nera Group" />
      </ImageWrapper>
      <TextWrapper>
        <Heading id="erbjudande">Vintererbjudande: Snöröjning</Heading>
        <Text>
          Vi på Nera Group erbjuder säsongsbunden snöskottning för både
          privatpersoner och företagskunder.
          <br />
          <br />
          Våra medarbetares tillgänglighet är dygnet runt, och vår snabba och
          effektiva respons är något vi är stolta över. Våra tjänster syftar
          till att säkerställa fri och enkel tillgång till parkeringsplatser,
          entréer till byggnader och hus, samt butiker.
          <br />
          <br />
          Vi sprider grus för att förhindra halka på isen och använder salt för
          att underlätta vinterväghållningen. Med vår hjälp kan du tryggt och
          smidigt navigera vintermånaderna.
        </Text>
      </TextWrapper>
    </Container>
  );
}
