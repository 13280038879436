import logo from '../src/assets/favicon.png';
import '../src/style/App.css';

import Navigation from './components/Navigation';
import HeroSection from './components/HeroSection';
import CardsSection from './components/CardsSection';
import ImageSection from './components/ImageSection';
import TextSection from './components/TextSection';
import ReversedTextSection from './components/ReversedTextSection';
import TwoColumnsSection from './components/TwoColumnsSection';
import Footer from './components/Footer';

export default function App() {
  return (
    <>
      <a href="/">
        <img src={logo} className="logo" alt="Nera Group logo" />
      </a>
      <Navigation />
      {/* <HeroSection /> */}
      <CardsSection />
      <ImageSection />
      <TextSection />
      {/* <ReversedTextSection /> */}
      <TwoColumnsSection />
      <Footer />
    </>
  );
}
