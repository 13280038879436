import { useState } from 'react';
import styled from 'styled-components';
import { sendEmail } from '../config/contact';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  padding: 3rem 1rem;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const FormLabel = styled.label`
  margin-bottom: 0.5rem;
`;

const FormInput = styled.input`
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 60%;
`;

const FormSelect = styled.select`
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 60%;
  border: 2px solid #ccc;
  border-radius: 5px;
`;

const FormTextarea = styled.textarea`
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 60%;
  height: 100px;
`;

const SubmitButton = styled.button`
  padding: 0.5rem 2rem;
  border: 2px solid #3e187a;
  border-radius: 50px;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #3e187a;
    color: #fff;
  }
`;

const MessageContainer = styled.div`
  margin-top: 1rem;

  p {
    color: ${({ success }) => (success ? 'green' : 'red')};
  }
`;

export default function Form() {
  const initialFormData = {
    name: '',
    email: '',
    phoneNumber: '',
    service: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const [submissionStatus, setSubmissionStatus] = useState({
    success: false,
    error: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendEmail(formData);
      setSubmissionStatus({ success: true, error: false });
      setFormData(initialFormData);
    } catch (error) {
      setSubmissionStatus({ success: false, error: true });
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormLabel htmlFor="name">Namn:</FormLabel>
      <FormInput
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        required
      />

      <FormLabel htmlFor="email">Epost:</FormLabel>
      <FormInput
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        required
      />

      <FormLabel htmlFor="phoneNumber">Telefonnummer:</FormLabel>
      <FormInput
        type="tel" // Use type="tel" for phone number input
        id="phoneNumber"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        required
      />

      <FormLabel htmlFor="service">Tjänster:</FormLabel>
      <FormSelect
        id="service"
        name="service"
        value={formData.service}
        onChange={handleInputChange}
      >
        {/* <option value="snoskottning">Snöskottning</option> */}
        <option value="bygg">Bygg</option>
        <option value="stad">Städ</option>
      </FormSelect>

      <FormLabel htmlFor="message">Meddelande:</FormLabel>
      <FormTextarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        required
      />

      <SubmitButton type="submit">Skicka</SubmitButton>
      <MessageContainer success={submissionStatus.success ? 1 : 0}>
        {submissionStatus.success && (
          <p>Tack så mycket! Meddelandet har skickats.</p>
        )}
        {submissionStatus.error && (
          <p>Något gick fel. Vänligen försök igen senare.</p>
        )}
      </MessageContainer>
    </FormContainer>
  );
}
