import Header from './components/Header';
import SubPageHero from './components/SubPageHero';
import SubPageTextSection from './components/SubPageTextSection';
import Footer from './components/Footer';

export default function Stad() {
  return (
    <>
      <Header />
      <SubPageHero serviceType="stad" />
      <SubPageTextSection serviceType="stad" />
      <Footer />
    </>
  );
}
