import styled from 'styled-components';
import img1 from '../assets/neragroup-portfolio-1.webp';
import img2 from '../assets/neragroup-portfolio-2.webp';
import img3 from '../assets/neragroup-portfolio-3.webp';
import img4 from '../assets/neragroup-portfolio-4.webp';

const Container = styled.div`
  margin: 3rem auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 4rem;
  }
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
`;

const Text = styled.p`
  color: black;
  text-align: left;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  padding: 1rem;
  gap: 2rem;

  @media (min-width: 768px) {
    margin: 0 2rem;
  }
`;

const Image = styled.img`
  border-radius: 15px;
  width: 100%;

  @media (min-width: 768px) {
    width: 25rem;
  }
`;

export default function PortfolioSection() {
  return (
    <Container>
      <TextWrapper>
        <Heading>Våra projekt</Heading>
        <Text>
          Nera Group är ett företag bestående av erfarna byggnadsarbetare som
          har hanterat många projekt de senaste åren över hela Stockholm och
          Södertälje med precision och högkvalitativ service. Under tiden har
          Nera ökat sina kapaciteter med verktyg, arbetskraft och expertis för
          att kunna möta och överträffa kundernas förväntningar.
        </Text>
      </TextWrapper>
      <ImageWrapper>
        <Image src={img1} alt="Nera Group" />
        <Image src={img2} alt="Nera Group" />
      </ImageWrapper>
      <ImageWrapper>
        <Image src={img3} alt="Nera Group" />
        <Image src={img4} alt="Nera Group" />
      </ImageWrapper>
    </Container>
  );
}
