import Header from './components/Header';
import SubPageHero from './components/SubPageHero';
import SubPageTextSection from './components/SubPageTextSection';
import Footer from './components/Footer';
import PortfolioSection from './components/PortfolioSection';

export default function Bygg() {
  return (
    <>
      <Header />
      <SubPageHero serviceType="bygg" />
      <SubPageTextSection serviceType="bygg" />
      <PortfolioSection />
      <Footer />
    </>
  );
}
