import { ses } from "./awsConfig";

export const sendEmail = async (formData) => {
  const { name, email, phoneNumber, service, message } = formData;

  const params = {
    Destination: {
      ToAddresses: ['info@neragroup.nu'],
    },
    Message: {
      Body: {
        Text: {
          Data: `Namn: ${name}\nEpost: ${email}\nTelefonnummer: ${phoneNumber}\nVald tjänst: ${service}\nMeddelande: ${message}`,
        },
      },
      Subject: { Data: 'Hemsida >>' },
    },
    Source: 'info@neragroup.nu',
  };

  try {
    await ses.sendEmail(params).promise();
  } catch (error) {
    console.error('Error sending email:', error);
  }
};
