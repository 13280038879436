import styled from 'styled-components';

const Nav = styled.nav`
  padding: 0.5rem 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MenuList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const MenuItem = styled.li`
  margin: 0 1.5rem;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
`;

const Link = styled.a`
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #3e187a;
  }
`;

const navigationItems = [
  // { id: 1, href: '/tjanster/snoskotning', element: 'Snöskottning' },
  // { id: 2, href: '/tjanster/stad', element: 'Städ' },
  { id: 2, href: 'https://agame.nu', element: 'Städ' },
  { id: 3, href: '/tjanster/bygg', element: 'Bygg' },
  { id: 4, href: '#om-oss', element: 'Om oss' },
  { id: 5, href: '/kontakt', element: 'Kontakt' },
];

export default function Navigation() {
  return (
    <Nav>
      <MenuList>
        {navigationItems.map((item) => (
          <MenuItem key={item.id}>
            <Link
              href={item.href}
              target={item.id === 2 ? '_blank' : '_self'}
              rel="noreferrer"
            >
              {item.element}
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Nav>
  );
}
