import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import App from './App.js';
import Kontakt from './Kontakt.jsx';
import Bygg from './Bygg.jsx';
import Stad from './Stad.jsx';
import Snoskotning from './Snoskotning.jsx';

import './style/index.css';
import './style/fonts/fonts.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/kontakt" exact component={Kontakt} />
      <Route path="/tjanster/bygg" exact component={Bygg} />
      <Route path="/tjanster/stad" exact component={Stad} />
      {/* <Route path="/tjanster/snoskotning" exact component={Snoskotning} /> */}
      <Route path="/" exact component={App} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);
