import styled from 'styled-components';
import img from '../assets/about-us-image.webp';

const Container = styled.div`
  margin: 3rem auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 4rem;
  }
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
`;

const Text = styled.p`
  color: black;
  text-align: left;
`;

const ImageWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
  padding: 0 1rem;

  @media (min-width: 768px) {
    margin: 0 2rem;
  }
`;

const Image = styled.img`
  border-radius: 15px;
  width: 100%;

  @media (min-width: 768px) {
    width: 25rem;
  }
`;

export default function TextSection() {
  return (
    <Container>
      <TextWrapper id="om-oss">
        <Heading>Upptäck vårt företag</Heading>
        <Text>
          Nera Group är ett växande företag med erfarna medarbetare och
          ansvarsfullt ledarskap. Vi agerar miljövänligt och bidrar till en
          hållbar framtid genom att använda enbart ekoprodukter och upprätthålla
          hög professionalism i vårt arbete. Vi erbjuder ett brett utbud av
          tjänster relaterade till byggnation, renovering, inomhus- och
          utomhusarbete, samt underhåll av trädgårdar och fastigheter.
          <br /> <br /> Vi tillhandahåller även städningstjänster för både hem
          och kontor, oavsett om det gäller privatpersoner eller företagskunder.
          Under vintersäsongen erbjuder vi snöröjning för vägar, parkeringar och
          privata trädgårdar. Vår strävan är att möta och överträffa våra
          kunders förväntningar och samtidigt främja en mer hållbar och
          miljövänlig framtid.
        </Text>
      </TextWrapper>
      <ImageWrapper>
        <Image src={img} alt="Nera Group" />
      </ImageWrapper>
    </Container>
  );
}
