import styled from 'styled-components';
import CheckIcon from './CheckIcon';

const Container = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  padding: 0 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
  }
`;

const Column = styled.div`
  text-align: center;
  margin: 2rem 0;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  margin: 0;
`;

const Subtitle = styled.h2`
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin: 0;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItems = styled.li`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  text-align: left;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const dataListForLeftColumn = [
  'Professionell hemstädning',
  'Storestädning - med högsta kvalitet',
  'Kontorsstädning - renhet och ordning för en trivsam arbetsmiljö',
  'Lokalstädning - städning av lokaler och företagsutrymmen',
  'Trappstädning och samarbete med din förening',
];

const dataListForRightColumn = [
  'Nybyggnation och renovering med professionell expertis',
  'Om- och tillbyggnad med högsta kvalitet och precision',
  'Byggnadsservice för att säkerställa en trivsam och fungerande arbetsmiljö',
  'Inredningsprojekt och anpassningar av lokaler efter behov',
  'Trappsteg och byggarbeten i samarbete med din förening',
];

export default function TwoColumnsSection() {
  return (
    <Container>
      <Column>
        <Title>Städ tjänster</Title>
        <Subtitle>
          Professionella städningstjänster för Hem och Företag
        </Subtitle>
        <List>
          {dataListForLeftColumn.map((item) => (
            <ListItems key={item}>
              <IconWrapper>
                <CheckIcon />
                {item}
              </IconWrapper>
            </ListItems>
          ))}
        </List>
      </Column>
      <Column>
        <Title>Bygg tjänster</Title>
        <Subtitle>Erfarna byggtjänster för ditt Framtidens Projekt</Subtitle>
        <List>
          {dataListForRightColumn.map((item) => (
            <ListItems key={item}>
              <IconWrapper>
                <CheckIcon />
                {item}
              </IconWrapper>
            </ListItems>
          ))}
        </List>
      </Column>
    </Container>
  );
}
