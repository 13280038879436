import Header from './components/Header';
import SubPageHero from './components/SubPageHero';
import Form from './components/Form';
import Footer from './components/Footer';

export default function Kontakt() {
  return (
    <>
      <Header />
      <SubPageHero serviceType="kontakt" />
      <Form />
      <Footer />
    </>
  );
}
