import styled from 'styled-components';

const Container = styled.div`
  margin: 3rem auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0 4rem;
  }
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
`;

const Text = styled.p`
  color: black;
  text-align: left;
`;

const CTA = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: start;
  }
`;

const Button = styled.a`
  padding: 1rem 2.5rem;
  border: 2px solid #3e187a;
  border-radius: 50px;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #3e187a;
    color: #fff;
  }

  &:first-child {
    margin-right: 2rem;
  }

  @media (max-width: 768px) {
    width: 7rem;
    text-align: center;
    padding: 0.5rem 1rem;

    &:first-child {
      margin-right: 0;
    }
  }
`;

const servicesInfo = {
  snoskotning: {
    id: 'snoskotning',
    title:
      'Säker snörengöring Dygnet Runt - Effektiv och tillgänglig service från Neragroup',
    description: (
      <>
        Upplev enastående snörengöringstjänster med Neragroup. Vårt dedikerade
        team erbjuder dygnet runt tillgänglighet och akutsvar för att hantera
        utmanande snöförhållanden. Från skickliga arbetare tillgängliga för
        uthyrning till vår effektiva maskinpark, specialiserar vi oss på
        omfattande snörengöringstjänster skräddarsydda för kommuner, företag och
        privata kunder.
        <br />
        <br />
        På Neragroup är vi stolta över vår mångsidiga inställning till
        snöborttagning. Oavsett om det handlar om att rensa allmänna områden,
        byggnadsentréer, privata gårdar, parkeringsplatser eller till och med
        tak, så har vi lösningen. Vår förpliktelse sträcker sig bortom effektiv
        rengöring - vi säkerställer en trygg miljö för våra arbetare under hårda
        vinterförhållanden.
        <br />
        <br />
        Utrustade med förstklassiga kläder och utrustning prioriterar vårt team
        ett miljövänligt tillvägagångssätt utan att kompromissa med säkerheten.
        Vi värdesätter både våra arbetares och kunders välbefinnande och strävar
        ständigt efter en säker och hållbar snörengöringsprocess.
        <br />
        <br />
        Snörengöring kan vara krävande, vilket är varför Neragroup är redo att
        hantera nödsituationer orsakade av kraftigt snöfall. Lita på oss för
        pålitliga och professionella snörengöringstjänster som garanterar
        säkerhet, trygghet och sinnesfrid, när som helst, dag eller natt.
      </>
    ),
  },
  bygg: {
    id: 'bygg',
    title: 'Precision och kvalitet i varje byggprojekt',
    description: (
      <>
        Nera Group är din partner för högkvalitativa byggtjänster. Vi strävar
        efter att leverera enastående resultat med vår noggranna och erfarna
        arbetsstyrka.
        <br />
        <br />
        Vårt fokus ligger inte bara på att bygga strukturer, utan även på att
        skapa hållbara inomhusmiljöer och robusta utomhusområden. Med en
        mångsidig expertis inom både interiör- och exteriörarbete erbjuder vi
        skräddarsydda lösningar för dina byggprojekt.
        <br />
        <br />
        Vår tillgänglighet sträcker sig till att erbjuda inte bara expertis utan
        också möjligheten att hyra skickliga arbetare, vilket säkerställer en
        smidig och professionell utförande av ditt byggprojekt.
      </>
    ),
  },
  stad: {
    id: 'stad',
    title: 'Skarp och smidig städning för hem och företag',
    description: (
      <>
        Nera Group är din pålitliga partner för omfattande städtjänster som
        sträcker sig bortom det vanliga. Vårt dedikerade team erbjuder
        skräddarsydda städlösningar för både privata hem och företagsmiljöer.
        <br />
        <br />
        Vi tar hand om allt, från kontorsstädning till djuprengöring av hemmet.
        Våra städtjänster inkluderar även specialiserade rengöringsåtgärder som
        flyttstädning, fönsterputsning och rengöring efter avflyttning eller vid
        behov av en omfattande städning.
        <br />
        <br />
        Vi strävar efter att skapa en ren och hälsosam miljö för våra kunder och
        erbjuder flexibla lösningar som matchar dina specifika behov.
      </>
    ),
  },
};

console.log('service', servicesInfo.stad.id === 'stad');

export default function SubPageTextSection({ serviceType }) {
  const { title, description } = servicesInfo[serviceType] || {};

  return (
    <Container>
      <TextWrapper id="om-oss">
        <Heading>{title || 'Välj en tjänst'}</Heading>
        <Text>{description || 'Välj en tjänst för att se information.'}</Text>
      </TextWrapper>
      <CTA>
        {serviceType === 'stad' ? (
          <Button href="https://agame.nu/" target="_blank">
            Läs mer på Agame Städ
          </Button>
        ) : (
          console.log('radi')
        )}
        <Button href="/kontakt">Kontakta oss</Button>
      </CTA>
    </Container>
  );
}
