import styled from 'styled-components';
import logo from '../assets/logo.webp';

const Container = styled.div`
  background-color: #3e187a;
  background-image: linear-gradient(316deg, #3e187a 0%, #994ecc 74%);
  color: #fff;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  padding: 0;
  width: 6rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const Details = styled.ul`
  list-style: none;
  padding: 0;
  text-align: center;
`;

const DetailsItems = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.5rem 0;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const detailsData = [
  { id: 1, route: 'mailto:info@neragrou.nu', element: 'info@neragrou.nu' },
  {
    id: 2,
    route: 'mailto:faktura@neragrou.nu',
    element: 'faktura@neragrou.nu',
  },
  { id: 3, route: 'tel:+46760541552', element: '076 054 15 52' },
];

export default function Footer() {
  return (
    <Container>
      <Logo src={logo} />
      <Text>Nera Group AB - Kontakta oss redan idag!</Text>
      <Details>
        {detailsData.map((item) => (
          <DetailsItems key={item.id}>
            <FooterLink href={item.route}>{item.element}</FooterLink>
          </DetailsItems>
        ))}
      </Details>
    </Container>
  );
}
