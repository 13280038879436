import styled from 'styled-components';
import '../style/fonts/fonts.css';
import snowImage from '../assets/snow-cleaning.webp';
import constructionImage from '../assets/building-home.webp';
import cleaningImage from '../assets/cleaning-crew.webp';

const Container = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    align-items: center;
    padding: 0 1rem;
  }
`;

const CardList = styled.ul`
  list-style: none;
  padding: 0;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
  }
`;

const CardListItem = styled.li`
  margin: 0 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 1.5rem;

  @media (min-width: 768px) {
    width: 20rem;
    margin: 0 2rem;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 200px;
  background-color: #3e187a;
  background-image: linear-gradient(316deg, #3e187a 0%, #994ecc 74%);
  border-radius: 15px 15px 0 0;
  margin-bottom: 1rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px 15px 0 0;
  }

  img:hover {
    transform: scale(1.05);
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3e187a;
`;

const Text = styled.p`
  font-size: 1rem;
  color: #333536;
  margin: 1rem 0;
  text-align: left;
`;

const CardButton = styled.a`
  margin: 1rem 0;
  padding: 0.5rem 1.5rem;
  border: 2px solid #3e187a;
  border-radius: 50px;
  transition: color 0.3s;
  width: 40%;

  &:hover {
    cursor: pointer;
    background-color: #3e187a;
    color: #fff;
  }
`;

const Button = styled.a`
  margin: 1rem 0;
  padding: 1rem 2.5rem;
  border: 2px solid #3e187a;
  border-radius: 50px;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #3e187a;
    color: #fff;
  }
`;

const cardData = [
  // {
  //   imageURL: snowImage,
  //   href: '/tjanster/snoskotning',
  //   title: 'Snöskottning',
  //   text: 'Snöskottning är en brådskande uppgift under vintern. Nera Group förstår vikten av omedelbar reaktion. Vårt dedikerade team är redo att ta hand om snön direkt. Vi garanterar snabb och pålitlig service, så att du kan vara trygg och säker, oavsett väder.',
  // },
  {
    imageURL: constructionImage,
    href: '/tjanster/bygg',
    title: 'Bygg',
    text: 'Nera Group är din pålitliga partner för byggprojekt både inomhus och utomhus. Med vår långa erfarenhet och höga kompetens levererar vi projekt av högsta kvalitet. Oavsett om det är inomhusrenoveringar eller utomhusbyggnadsarbete, kan du lita på oss för att få jobbet gjort.',
  },
  {
    imageURL: cleaningImage,
    href: '/tjanster/stad',
    title: 'Städ',
    text: 'Nera Group erbjuder professionella städtjänster för både privata och företagskunder. Vårt dedikerade team ser till att ditt utrymme alltid är rent och fräscht. Oavsett om du behöver hemstädning eller kontorsrengöring, kan du lita på oss för att skapa en ren och trivsam miljö.',
  },
];

export default function CardsSection() {
  return (
    <Container>
      <CardList>
        {cardData.map((card, index) => (
          <a href={card.href}>
            <CardListItem key={index}>
              <Image>
                <img src={card.imageURL} alt={card.title} />
              </Image>
              <Title>{card.title}</Title>
              <Text>{card.text}</Text>
              <CardButton href={card.href}>Läs mer</CardButton>
            </CardListItem>
          </a>
        ))}
      </CardList>
      <Button href="/kontakt">Gör en intresseanmälan</Button>
    </Container>
  );
}
