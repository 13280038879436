import AWS from 'aws-sdk';

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
  credentials: {
    accessKeyId,
    secretAccessKey,
  },
  region,
});

const ses = new AWS.SES({ apiVersion: '2010-12-01' });

export { ses };