import { useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/favicon.png';
import '../../src/style/App.css';

const Nav = styled.nav`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #7d5cff, #3e187a);
  position: relative;

  @media (max-width: 768px) {
    .menu-list {
      display: ${({ toggle }) => (toggle ? 'flex' : 'none')};
      flex-direction: column;
      position: absolute;
      top: calc(100% + 10px);
      right: 10px; /* Add space on the right side of the menu */
      background: rgba(62, 24, 122, 0.9);
      padding: 1rem;
      z-index: 999;
      border-radius: 5px;
    }
  }
`;

const LogoLink = styled.a`
  margin-left: 1rem;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const MenuList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0 2rem 0 0;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-top: 0;
    flex-direction: column;
  }
`;

const MenuItem = styled.li`
  margin: 0.5rem 0;
  font-size: 1.2rem;
  
  padding: 0.5rem 2rem;
  background-color: transparent;
  border-radius: 3px;

  a {
    color: white;
  }

  &:hover {
    background-color: rgba(
      255,
      255,
      255,
      0.3
    );
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    padding-right: 2rem;
  }
`;

const Bar = styled.div`
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
`;

const navigationItems = [
  // { id: 1, href: '/tjanster/snoskotning', element: 'Snöskottning' },
  // { id: 2, href: '/tjanster/stad', element: 'Städ' },
  { id: 2, href: 'https://agame.nu', element: 'Städ' },
  { id: 3, href: '/tjanster/bygg', element: 'Bygg' },
  { id: 4, href: '/kontakt', element: 'Kontakt' },
];

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Nav toggle={isOpen}>
      <LogoLink href="/">
        <Logo src={logo} className="logo" alt="Nera Group logo" />
      </LogoLink>
      <HamburgerIcon onClick={toggleMenu}>
        <Bar />
        <Bar />
        <Bar />
      </HamburgerIcon>
      <MenuList className="menu-list">
        {navigationItems.map((item) => (
          <MenuItem key={item.id}>
            <a
              href={item.href}
              target={item.id === 2 ? '_blank' : '_self'}
              rel="noreferrer"
            >
              {item.element}
            </a>
          </MenuItem>
        ))}
      </MenuList>
    </Nav>
  );
}
