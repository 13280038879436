import styled from 'styled-components';
import blob from '../assets/blob.svg';
import blobBg from '../assets/blob-bg.svg';
import '../style/fonts/fonts.css';

const Container = styled.div`
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  background-image: url(${blobBg});
  background-repeat: no-repeat;
  background-position: right;
  text-align: center;

  @media (max-width: 768px) {
    height: auto;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    align-items: center;
  }
`;

const Shape = styled.div`
  position: absolute;
  top: 10%;
  right: 10%;
  width: 400px;
  height: 400px;
  background-image: url(${blob});
  background-size: cover;
  z-index: -1;

  @media (max-width: 768px) {
    top: 0;
    right: 0;
    opacity: 0.3;
  }
`;

const Headline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #3e187a;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const Subtitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.3rem;
  font-weight: 600;
  color: #cea64b;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const PitchText = styled.p`
  font-size: 1rem;
  color: #333536;

  @media (max-width: 768px) {
    font-weight: bold;
  }
`;

const CTA = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: start;
  }
`;

const Button = styled.a`
  padding: 1rem 2.5rem;
  border: 2px solid #3e187a;
  border-radius: 50px;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #3e187a;
    color: #fff;
  }

  &:first-child {
    margin-right: 2rem;
  }

  @media (max-width: 768px) {
    width: 7rem;
    text-align: center;
    padding: 0.5rem 1rem;

    &:first-child {
      margin-right: 0;
    }
  }
`;

export default function HeroSection() {
  return (
    <Container>
      <Shape />
      <Headline>
        <Title>Vinterservice för snöskottning</Title>
        <Subtitle>Nera Group</Subtitle>
        <PitchText>
          Nera Group erbjuder högkvalitativa tjänster för snöskottning under
          vintersäsongen, samt standardtjänster inom Bygg och Städ. Vi är din
          pålitliga partner för alla säsonger.
        </PitchText>
        <CTA>
          <Button href="#erbjudande">Erbjudande</Button>
          <Button href="/kontakt">Kontakta oss</Button>
        </CTA>
      </Headline>
    </Container>
  );
}
