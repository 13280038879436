import styled from 'styled-components';
import snowCleaningImg from '../assets/cleaning-snow.webp';
import byggImg from '../assets/construction-site.webp';
import stadImg from '../assets/cleaning-service.webp';
import kontaktImg from '../assets/contact.webp';

const Container = styled.div`
  height: 35rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  z-index: 10;
  object-fit: cover;
`;

export default function ImageSection({ serviceType }) {
  let imgSrc = '';

  switch (serviceType) {
    case 'snoskotning':
      imgSrc = snowCleaningImg;
      break;
    case 'bygg':
      imgSrc = byggImg;
      break;
    case 'stad':
      imgSrc = stadImg;
      break;
    case 'kontakt':
      imgSrc = kontaktImg;
      break;
    default:
      imgSrc = snowCleaningImg;
      break;
  }

  return (
    <Container>
      <Overlay />
      <Image src={imgSrc} alt="Service" />
    </Container>
  );
}
