import styled from 'styled-components';
import img from '../assets/stockholm.webp';

const Container = styled.div`
  margin-top: 3rem;
  height: 25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  z-index: 10;
  object-fit: cover;
`;

export default function ImageSection() {
  return (
    <Container>
      <Overlay />
      <Image src={img} alt="Stockholm" />
    </Container>
  );
}
